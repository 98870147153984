import { createReducer } from 'utils/reduxHelpers';

export const ON_AUTH_SATE = '@@auth/ON_AUTH_SATE';
export const ON_SET_ACCOUNT = '@@auth/ON_SET_ACCOUNT';
export const ON_LOGOUT = '@@auth/ON_LOGOUT';

export const onAuthState = payload => ({ type: ON_AUTH_SATE, payload });
export const onSetAccount = payload => ({ type: ON_SET_ACCOUNT, payload });
export const onLogout = () => ({ type: ON_LOGOUT });

const isAuthHandlers = {
  [ON_AUTH_SATE]: (state, action) => action.payload,
  [ON_LOGOUT]: () => null,
};

const accountHandlers = {
  [ON_SET_ACCOUNT]: (state, action) => action.payload,
  [ON_LOGOUT]: () => null,
};

export const authStates = {
  ROLE_USER: 2,
  ROLE_GUEST: 1,
};

export const isAuthInitialState = null;
export const accountInitialState = null;

export const accountReducer = createReducer(accountInitialState, accountHandlers);
export const isAuthReducer = createReducer(isAuthInitialState, isAuthHandlers);
