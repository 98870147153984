import { createReducer } from 'utils/reduxHelpers';
import { ON_LOGOUT } from 'app/Auth/actions/auth';
import { getStoreData, removeFromStore, saveStoreData } from 'utils/storeHelpers';
import { Api } from 'utils/connectors';
import { getArrayIds, getLang } from 'utils/appHelpers';
import { fillMoviesData, onSetMoviesData } from 'app/Dash/actions/movie';

const ON_MY_LIST_GET = '@@home/ON_MY_LIST_GET';

export const onSetMyList = payload => {
  return { type: ON_MY_LIST_GET, payload };
};

const myListHandlers = {
  [ON_MY_LIST_GET]: (state, action) => {
    const result = { ...state, ...action.payload };
    saveStoreData('redux_my_list', result);
    return result;
  },
  [ON_LOGOUT]: () => {
    removeFromStore('redux_my_list');
    return [];
  },
};

export const getMyList = isNext => async (dispatch, getState) => {
  try {
    const myListStore = getState().myList;
    const page = isNext ? myListStore.number + 1 : 0;
    const params = {
      page,
      size: 100,
      locale: getLang(),
    };
    const { data } = await Api.get('/medias/my-list', { params });
    dispatch(fillMoviesData([...data.content]));
    data.content = getArrayIds(data.content);
    if (isNext) data.content = [...myListStore.content, ...data.content];
    dispatch(onSetMyList(data));
  } catch (err) {
    throw err;
  }
};

export const addOnMyList = movieId => async (dispatch, getState) => {
  const { movies, myList } = getState();
  const tempMovies = { ...movies };
  tempMovies[movieId].is_in_my_list = true;
  dispatch(onSetMoviesData(tempMovies));
  const tempList = { ...myList };
  if (tempList && tempList.content) {
    tempList.content.unshift({ id: movieId });
    tempList.total_elements += 1;
    dispatch(onSetMyList(tempList));
  }
};

export const removeOnMyList = movieId => async (dispatch, getState) => {
  const { movies, myList } = getState();
  const tempMovies = { ...movies };
  tempMovies[movieId].is_in_my_list = false;
  dispatch(onSetMoviesData(tempMovies));
  const tempList = { ...myList };
  if (tempList && tempList.content) {
    const index = tempList.content.findIndex(i => i.id === movieId);
    tempList.content.splice(index, 1);
    tempList.total_elements -= 1;
    dispatch(onSetMyList(tempList));
  }
};

export const toggleMyList = movie => async dispatch => {
  movie.is_in_my_list ? dispatch(removeOnMyList(movie.id)) : dispatch(addOnMyList(movie.id));
};

const myListInitialData = getStoreData('redux_my_list') || null;

export const myListReducer = createReducer(myListInitialData, myListHandlers);
