import { combineReducers } from 'redux';
import { accountReducer, isAuthReducer } from 'app/Auth/actions/auth';
import { movieReducer } from 'app/Dash/actions/movie';
import { postersReducer } from 'app/Dash/routes/Movies/actions/posters';
import { myListReducer } from 'app/Dash/routes/MyList/actions';
import { myWatchListReducer } from 'app/Dash/routes/MyWatchedList/actions';
import { categoriesReducer } from 'app/Dash/routes/Movies/actions/categories';
import { userInfoReducer } from 'app/Dash/actions/userInfo';
import { plansReducer } from 'app/Dash/actions/plans';

// Load reducers
const rootReducer = combineReducers({
  isAuthenticated: isAuthReducer,
  account: accountReducer,
  movies: movieReducer,
  posters: postersReducer,
  myList: myListReducer,
  myWatchList: myWatchListReducer,
  categories: categoriesReducer,
  userInfo: userInfoReducer,
  plans: plansReducer,
});

export default rootReducer;
