import React from 'react';
import * as Icons from 'shared/components/IconsV2';

const Button = ({
  appearance = 'primary',
  size = 'md',
  block,
  className = '',
  iconClass = '',
  icon,
  children,
  iconWidth,
  iconHeight,
  onClick = () => {},
  href,
  ...props
}) => {
  const Icon = Icons[`Icon${icon}`];

  const NativeButton = props => <button {...props}>{props.children}</button>;

  const NativeA = props => <a {...props}>{props.children}</a>;

  const MainElement = href ? NativeA : NativeButton;

  return (
    <MainElement
      className={`btn btn-v2 btn-v2-${appearance} btn-v2-${size}${
        block ? ' btn-block' : ''
      } ${className}`}
      onClick={onClick}
      href={href}
      {...props}
    >
      {Icon && <Icon className={`icon ${iconClass}`} width={iconWidth} height={iconHeight} />}
      {children && <span>{children}</span>}
    </MainElement>
  );
};

export default Button;
