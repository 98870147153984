import { ON_LOGOUT } from 'app/Auth/actions/auth';
import { createReducer } from 'utils/reduxHelpers';

const ON_SET_MOVIES = '@@movies/ON_SET_MOVIES';
const ON_SET_MOVIE = '@@movies/ON_SET_MOVIE';

export const onSetMoviesData = payload => ({ type: ON_SET_MOVIES, payload });
export const onSetMovieData = payload => ({ type: ON_SET_MOVIE, payload });

export const fillMoviesData = (array = []) => dispatch => {
  const result = {};
  array.forEach(item => {
    const media = item.media ? item.media : item;
    result[media.id] = { ...media };
  });
  dispatch(onSetMoviesData(result));
};

const getUpdatingMovieObj = movie => {
  const tempMovie = { ...movie };
  if (tempMovie.seasons && !tempMovie.seasons.length) {
    delete tempMovie.seasons;
  }
  return tempMovie;
};

const mergeStateAndPayload = (state, data) => {
  const result = { ...state };
  Object.keys(data).forEach(key => {
    if (result[key]) {
      const newUpdateMovieState = getUpdatingMovieObj(data[key]);
      result[key] = { ...result[key], ...newUpdateMovieState };
    } else {
      result[key] = data[key];
    }
  });
  return result;
};

const movieDataHandlers = {
  [ON_SET_MOVIES]: (state, action) => {
    const mergedState = mergeStateAndPayload(state, action.payload);
    return mergedState;
  },
  [ON_SET_MOVIE]: (state, action) => {
    const tempState = { ...state };
    const movie = action.payload;
    tempState[movie.id] = { ...(tempState[movie.id] || {}), ...action.payload };
    return tempState;
  },
  [ON_LOGOUT]: () => {
    return {};
  },
};

const movieInitialData = {};

export const movieReducer = createReducer(movieInitialData, movieDataHandlers);
