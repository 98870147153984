export const saveToStore = (name, value) => {
  if (!localStorage || !localStorage.setItem) return;
  localStorage.setItem(name, value);
};

export const getFromStore = (name, defaultValue = false) => {
  if (!localStorage || !localStorage.getItem) return defaultValue;
  const res = localStorage.getItem(name);
  return res === undefined || res === null ? defaultValue : res;
};

export const removeFromStore = name => {
  if (!localStorage || !localStorage.removeItem) return;
  localStorage.removeItem(name);
};

export const removeAllStore = () => {
  if (!localStorage || !localStorage.clear) return;
  localStorage.clear();
};

export const saveStoreData = (name, obj) => {
  try {
    const result = { valid: new Date(), data: obj };
    saveToStore(name, JSON.stringify(result));
  } catch (err) {}
};

export const dayDiffer = day => {
  const date1 = new Date(day);
  const date2 = new Date();
  const Difference_In_Time = date2.getTime() - date1.getTime();
  return Difference_In_Time / (1000 * 3600 * 24);
};

export const saveToSessionStore = (name, value) => {
  if (!sessionStorage || !sessionStorage.setItem) return;
  sessionStorage.setItem(name, value);
};

export const getFromSessionStore = (name, defaultValue = false) => {
  if (!sessionStorage || !sessionStorage.getItem) return defaultValue;
  const res = sessionStorage.getItem(name);
  return res === undefined || res === null ? defaultValue : res;
};

export const removeFromSessionStore = name => {
  if (!sessionStorage || !sessionStorage.removeItem) return;
  sessionStorage.removeItem(name);
};

export const getStoreData = name => {
  return null;
  // try {
  //   const storeObj = getFromStore(name);
  //   const result = storeObj && JSON.parse(storeObj);
  //   const isValid = result && result.valid && dayDiffer(result.valid) < 0.1;
  //   if (!isValid) removeFromStore(name);
  //   return result && isValid ? result.data : null;
  // } catch (err) {
  //   return null;
  // }
};
