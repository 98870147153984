// Load vendors
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// Load configs
import { HOST } from 'configs/host';
import { getRefreshToken, saveLocalToken } from './tokenHelpers';
import { getDeviceId, getDeviceInfo, getDeviceType } from './appHelpers';
import { clearForLogout } from 'app/Auth/actions/authActions';
import packJson from '../../package.json';

const getTraceHeader = () => {
  const deviceInfo = getDeviceInfo();
  return {
    // eslint-disable-next-line prettier/prettier
    'X-Amzn-Trace-Id': `${deviceInfo.browser}_${deviceInfo.version}_${packJson.version}_${uuidv4()}`,
    'device-id': getDeviceId(),
    deviceId: getDeviceId(),
  };
};

// Set config defaults when creating the instance
export const Api = axios.create({
  baseURL: `${HOST.API.URL}/`,
  // THIS HEADERS IS IMPORTANT SEE THE URL ABOVE
  headers: { common: { ...getTraceHeader() } },
});

// Set config defaults when creating the instance
export const PublicApi = axios.create({
  baseURL: `${HOST.API.URL}/`,
  headers: { common: { ...getTraceHeader() } },
});

export const AnalyticsApi = axios.create({
  baseURL: HOST.API.ANALYTICS_API,
  // THIS HEADERS IS IMPORTANT SEE THE URL ABOVE
  headers: { common: {} },
});

export const setAuthToken = authToken => {
  Api.defaults.headers.common['Authorization'] = `${HOST.API.AUTH_PREFIX} ${authToken}`;
  AnalyticsApi.defaults.headers.common['Authorization'] = `${HOST.API.AUTH_PREFIX} ${authToken}`;
};

const refreshToken = async (axios, config) => {
  return new Promise(async (resolve, reject) => {
    try {
      const options = { headers: { common: { 'refresh-token': getRefreshToken() } } };
      const { data } = await Api.post('/auth/refresh-token', {}, options);
      saveLocalToken(data.token, data.refresh_token);
      setAuthToken(data.token);
      config.headers.Authorization = `${HOST.API.AUTH_PREFIX} ${data.token}`;
      config.headers['device-type'] = getDeviceType();
      return axios
        .request(config)
        .then(resolve)
        .catch(reject);
    } catch (err) {
      return reject(err);
    }
  });
};

export const initRefreshToken = store => {
  Api.interceptors.response.use(null, error => {
    if (error.name === 'CanceledError') return Promise.resolve({ status: 499 });
    if (error.config.noRefresh) {
      return Promise.reject(error);
    }
    const isRefresh = error.config.url === '/auth/refresh-token';
    if (!isRefresh && error.config && error.response?.status === 401 && !error.config.__isRetry) {
      return new Promise((resolve, reject) => {
        return refreshToken(axios, error.config)
          .then(resolve)
          .catch(() => clearForLogout(store.dispatch));
      });
    }
    return Promise.reject(error);
  });
};
