import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import packJson from '../../package.json';
import { initReactI18next } from 'react-i18next';
import 'moment/locale/hy-am';

export const languages = [
  {
    name: 'English',
    shortName: 'Eng',
    value: 'en',
    code: 'en',
    longCode: 'en-US',
  },
  {
    name: 'Հայերեն',
    shortName: 'Հայ',
    value: 'hy',
    code: 'hy',
    longCode: 'hy-AM',
  },
  {
    name: 'Русский',
    shortName: 'Рус',
    value: 'ru',
    code: 'ru',
    longCode: 'ru-RU',
  },
];

export const translateItem = (item, langKey, lanSubKey) => {
  const tname = `${lanSubKey}_${String(item).toLowerCase()}`.replace(' ', '_');
  return i18n.t([`${langKey}.${tname}`, item]);
};

const fallbackLng = ['en'];
const whitelist = languages.map(item => item.code);

const configs = {
  fallbackLng,
  whitelist,
  nonExplicitWhitelist: true,
  cleanCode: true,
  initImmediate: false,
  load: 'languageOnly',
  interpolation: {
    escapeValue: false,
  },
  detection: {
    lookupQuerystring: 'lang',
    lookupCookie: 'lang',
    lookupLocalStorage: 'lang',
  },
  backend: {
    queryStringParams: { v: packJson.version },
  },
};

i18n
  .use(Backend)
  .use(intervalPlural)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(configs);
