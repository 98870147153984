import React from 'react';
import { createRoot } from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { initRefreshToken } from 'utils/connectors';
import { Suspense } from 'react';
import { initSentry, isProduction } from 'configs';
import App from './app/index';
import store from './redux/store';
import Modal from 'react-modal';
import Loading from 'shared/components/Loading';
import 'scss/app.scss';
import './lang';

const snackOpts = { vertical: 'top', horizontal: 'right' };
if (isProduction) initSentry();
Modal.setAppElement('#root');
initRefreshToken(store);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <Suspense fallback={<Loading />}>
      <SnackbarProvider maxSnack={3} anchorOrigin={snackOpts} autoHideDuration={3000}>
        <App />
      </SnackbarProvider>
    </Suspense>
  </Provider>,
);
