import React from 'react';
import store from 'redux/store';
import { confirmAlert } from 'react-confirm-alert';
import { Provider } from 'react-redux';
import ConfirmModal from 'shared/components/modals/ConfirmModal';

export const confirmBox = options => {
  confirmAlert({
    customUI: ({ onClose }) => (
      <Provider store={store}>
        <ConfirmModal onClose={onClose} {...options} />
      </Provider>
    ),
  });
};
