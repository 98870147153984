import React from 'react';

const Loading = ({ className, classSpinner }) => (
  <div
    className={`align-items-center d-flex h-100 justify-content-center text-primary w-100 ${className ||
      'position-absolute'}`}
  >
    <div className={`spinner-border ${classSpinner || ''}`} role='status' />
  </div>
);

export default Loading;
