import { getFromStore, removeFromStore, saveToStore } from './storeHelpers';

export const saveLocalToken = (token, refreshToken) => {
  saveToStore('authWeb', token);
  saveToStore('authWebRefresh', refreshToken);
};

export const getLocalToken = () => {
  return getFromStore('authWeb');
};

export const getRefreshToken = () => {
  return getFromStore('authWebRefresh');
};

export const removeLocalToken = () => {
  removeFromStore('authWeb');
  removeFromStore('refreshToken');
};
