import { createReducer } from 'utils/reduxHelpers';
import { ON_LOGOUT } from 'app/Auth/actions/auth';
import { getStoreData, removeFromStore, saveStoreData } from 'utils/storeHelpers';
import { fillMoviesData } from 'app/Dash/actions/movie';

const ON_CATEGORIES_GET = '@@home/ON_CATEGORIES_GET';

export const onSetCategories = payload => {
  return { type: ON_CATEGORIES_GET, payload };
};

const categoriesHandlers = {
  [ON_CATEGORIES_GET]: (state, action) => {
    const result = { ...state, ...action.payload };
    saveStoreData('redux_categories', result);
    return result;
  },
  [ON_LOGOUT]: () => {
    removeFromStore('redux_categories');
    return [];
  },
};

export const onSetCategoriesItems = response => async (dispatch, getState) => {
  try {
    const movies = [];
    const categories = [];
    response.content.forEach(category => {
      const ctgRow = { id: category.id, name: category.name, items: [] };
      ctgRow.items = category.items.content.map(item => {
        movies.push(item.media);
        return { id: item.id };
      });
      categories.push(ctgRow);
    });
    response.content = categories;
    dispatch(fillMoviesData(movies));
    dispatch(onSetCategories(response));
  } catch (err) {
    throw err;
  }
};

const categoriesInitialData = getStoreData('redux_categories') || null;

export const categoriesReducer = createReducer(categoriesInitialData, categoriesHandlers);
