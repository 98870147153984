import { createReducer } from 'utils/reduxHelpers';
import { ON_LOGOUT } from 'app/Auth/actions/auth';
import { getStoreData, removeFromStore, saveStoreData } from 'utils/storeHelpers';

const ON_POSTERS_GET = '@@home/ON_POSTERS_GET';

export const onSetPosters = payload => {
  return { type: ON_POSTERS_GET, payload };
};

const postersHandlers = {
  [ON_POSTERS_GET]: (state, action) => {
    const result = action.payload;
    saveStoreData('redux_posters', result);
    return result;
  },
  [ON_LOGOUT]: () => {
    removeFromStore('redux_posters');
    return null;
  },
};

const posterInitialData = getStoreData('redux_posters') || null;

export const postersReducer = createReducer(posterInitialData, postersHandlers);
