import React, { lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { getAuthState } from './Auth/actions/authActions';
import { BrowserRouter as Router } from 'react-router-dom';
import packageJson from './../../package.json';
import Modals from './Modals';

const Loading = lazy(() => import('shared/components/Loading'));
const UI = lazy(() => import('./UI'));
const Dash = lazy(() => import('./Dash'));
const Main = lazy(() => import('./Main'));
const About = lazy(() => import('shared/components/pages/About'));
const ScrollToTop = lazy(() => import('shared/components/layouts/ScrollToTop'));
const NotFound = lazy(() => import('shared/components/pages/NotFound'));
const GeoBlocked = lazy(() => import('shared/components/pages/GeoBlocked'));
const Device = lazy(() => import('shared/components/pages/Device'));
const ShareLink = lazy(() => import('shared/components/pages/ShareLink'));
const Faq = lazy(() => import('shared/components/pages/Faq'));
const Privacy = lazy(() => import('shared/components/pages/Privacy'));
const Terms = lazy(() => import('shared/components/pages/Terms'));
const EmailUnsubscribe = lazy(() => import('shared/components/pages/EmailUnsubscribe'));

const App = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector(state => state.isAuthenticated);
  const [browserBackState, setBrowserBackState] = useState(false);

  useEffect(() => {
    dispatch(getAuthState());
    document.body?.setAttribute('_version', packageJson.version);
    window.addEventListener('popstate', function(event) {
      setBrowserBackState(event?.state.key);
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isAuth]);

  if (isAuth === null) return <Loading />;

  return (
    <Router basename={process.env.PUBLIC_URL} key={browserBackState}>
      <ScrollToTop />
      <Switch>
        <Route path='/ui' component={UI} />
        <Route path='/link/auth' component={Device} />
        <Route path='/device' component={Device} />
        <Route path='/privacy' component={Privacy} />
        <Route path='/term' component={Terms} />
        <Route path='/faq/:question?/:platform?' component={Faq} />
        <Route path='/about' component={About} />
        <Route path='/404' component={NotFound} />
        <Route path='/blocked' component={GeoBlocked} />
        <Route path='/link/media' component={ShareLink} />
        <Route path='/unsubscribe' component={EmailUnsubscribe} />
        <Route path='/' component={isAuth ? Dash : Main} />
      </Switch>
      <Modals />
    </Router>
  );
};

export default App;
