import { ON_LOGOUT } from 'app/Auth/actions/auth';
import { createReducer } from 'utils/reduxHelpers';

const ON_SET_VOTES = '@@user/ON_SET_VOTES';
const ON_UPDATE_VOTES = '@@user/ON_UPDATE_VOTES';
const ON_REMOVE_VOTES = '@@user/ON_REMOVE_VOTES';
const ON_SET_USER_PLANS = '@@user/ON_SET_USER_PLANS';
const ON_SET_USER_LOCATION = '@@user/ON_SET_USER_LOCATION';

export const onSetUserVotes = payload => ({ type: ON_SET_VOTES, payload });
export const onUpdateUserVote = payload => ({ type: ON_UPDATE_VOTES, payload });
export const onRemoveUserVote = payload => ({ type: ON_REMOVE_VOTES, payload });
export const onSetUserPlans = payload => ({ type: ON_SET_USER_PLANS, payload });
export const onSetUserLocation = payload => ({ type: ON_SET_USER_LOCATION, payload });

const userInitialData = { votes: {}, plans: {} };

const userInfoHandlers = {
  [ON_SET_VOTES]: (state, action) => {
    const votes = {};
    action.payload.forEach(i => {
      votes[i.media_id] = i.vote;
    });
    return { ...state, votes };
  },
  [ON_UPDATE_VOTES]: (state, action) => {
    const { vote, id } = action.payload;
    const tempData = { ...state };
    tempData.votes[id] = vote;
    return tempData;
  },
  [ON_REMOVE_VOTES]: (state, action) => {
    const id = action.payload;
    const tempData = { ...state };
    delete tempData.votes[id];
    return tempData;
  },
  [ON_SET_USER_PLANS]: (state, action) => {
    const result = {};
    action.payload.forEach(item => {
      result[item.subscription_plan_id] = item;
    });
    return { ...state, plans: { ...state.plans, ...result } };
  },
  [ON_SET_USER_LOCATION]: (state, action) => {
    return { ...state, location: action.payload };
  },
  [ON_LOGOUT]: () => {
    return userInitialData;
  },
};

export const userInfoReducer = createReducer(userInitialData, userInfoHandlers);
