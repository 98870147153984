import React from 'react';
import { Redirect, Route } from 'react-router-dom';

let userPermissions = {};

const permissions = {
  ROLE_GUEST: ['guest_user'],
  ROLE_USER: ['main_user'],
};

export const setUserAppPermissions = user => {
  userPermissions = {};
  const { authorities } = user;
  permissions[authorities[0]].forEach(perm => {
    userPermissions[perm] = true;
  });
};

export const hasAccess = name => {
  return !!userPermissions[name];
};

export const PRoute = props => {
  if (props.access && !hasAccess(props.access)) return <Redirect to='/' />;
  return <Route {...props} />;
};
