import React, { useState } from 'react';
import Button from '../buttons/Button';

const ConfirmModal = ({
  icon,
  title,
  message,
  cancel,
  confirm,
  onSuccess,
  onClose,
  className = '',
  btnConfirmClassName = '',
}) => {
  const [fetching, setFetch] = useState(false);

  const handleConfirmClick = async () => {
    if (fetching) return;
    setFetch(true);
    if (onSuccess) await onSuccess();
    setFetch(false);
    onClose();
  };

  return (
    <div className={`confirm-modal ${className}`}>
      <div className='px-v2-12 pt-v2-12 pb-v2-16'>
        {title && (
          <p className='text-md-alt weight-500 mb-v2-8 d-flex align-items-center'>
            {icon && icon}
            <span className={icon ? 'ml-v2-6' : ''}>{title}</span>
          </p>
        )}
        {message && (
          <p className='text-sm-alt weight-400 text-v2-light-alt mb-0 overflow-hidden'>{message}</p>
        )}
      </div>
      <div className='d-flex align-items-center justify-content-end px-v2-14 py-v2-8 border-top border__extra_dark'>
        {cancel && (
          <Button
            type='button'
            appearance=''
            className='text-v2-extra-light text-uppercase p-0'
            onClick={onClose}
          >
            {cancel}
          </Button>
        )}
        {confirm && (
          <Button
            type='button'
            appearance=''
            className={`text-uppercase ml-v2-12 p-0 ${btnConfirmClassName}`}
            disabled={fetching}
            onClick={handleConfirmClick}
          >
            {confirm}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ConfirmModal;
