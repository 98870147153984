/* global google */
import { getUserInfos } from 'app/Dash/actions/userInfoActions';
import { logEvent, setLogUserProperties } from 'utils/analyticsHelper';
import { Api, setAuthToken } from 'utils/connectors';
import { hasAccess, setUserAppPermissions } from 'utils/permissionHelper';
import { getLocalToken, removeLocalToken, saveLocalToken } from 'utils/tokenHelpers';
import { authStates, onAuthState, onLogout, onSetAccount } from './auth';
import {
  removeFromSessionStore,
  removeFromStore,
  saveToSessionStore,
  saveToStore,
} from 'utils/storeHelpers';

export const clearForLogout = dispatch => {
  dispatch(onLogout());
  removeLocalToken();
  dispatch(onAuthState(0));
  setAuthToken('', Api);
  removeFromSessionStore('ad-w-count');
  removeFromSessionStore('ad-w-seconds');
  removeFromSessionStore('redPath');
  removeFromStore('authGuest');
};

export const getProfile = async () => {
  try {
    const data = await Api.get('/users/me');
    const adSettings = await Api.get('/ad-configs');
    data.data.ad_config = adSettings.data;
    setLogUserProperties(data.data);
    saveToStore('authLetter', data.data.name.charAt(0));
    return data.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAuthState = () => async dispatch => {
  const token = getLocalToken();
  const url = new URL(window.location.href);
  const isLoginGuestFromSEO = url.searchParams?.get('isl') === 'true';

  if (isLoginGuestFromSEO) {
    saveToSessionStore('redPath', url.pathname);
  }

  if (token) {
    // set common headers
    setAuthToken(token, Api);

    try {
      const user = await getProfile();
      const state = authStates[user.authorities[0]];
      // Set Perrmissions
      setUserAppPermissions(user);
      // getUserSpecificData
      if (!hasAccess('guest_user')) await getUserInfos();
      // Keep user in store
      await dispatch(onSetAccount(user));
      dispatch(onAuthState(state));
    } catch (err) {
      // Set Aut State 0 - is logout state (Activation)
      dispatch(onAuthState(0));
      // Set logout
      clearForLogout(dispatch);
    }
  }
  // Set Aut State 0 - is logout state (Activation)
  else dispatch(onAuthState(0));
};

export const loginUser = (data, onSuccess) => async dispatch => {
  try {
    const { user, access_token } = data;
    const { token, refresh_token } = access_token;
    user.authorities = user.authorities || ['ROLE_USER'];
    saveLocalToken(token, refresh_token);
    setAuthToken(token, Api);
    // Set User Profile
    const dbUser = await getProfile();
    // Send User Id
    // Set Perrmissions
    setUserAppPermissions(dbUser);
    // getUserSpecificData
    await getUserInfos();
    // Set User Profile
    await dispatch(onSetAccount(dbUser));
    const state = authStates[dbUser.authorities[0]];
    dispatch(onAuthState(state));
    onSuccess && onSuccess();
    removeFromStore('authGuest');
  } catch (err) {
    // Set Aut State 0 - is logout state (Activation)
    dispatch(onAuthState(0));
    clearForLogout(dispatch);
  }
};

export const loginGuest = data => async dispatch => {
  try {
    const { token, refresh_token } = data;
    saveLocalToken(token, refresh_token);
    setAuthToken(token, Api);
    // Set User Profile
    const user = await getProfile();
    // Set Perrmissions
    setUserAppPermissions(user);
    // Set User Profile
    await dispatch(onSetAccount(user));
    const state = authStates[user.authorities[0]];
    dispatch(onAuthState(state));
    logEvent('browse');
    saveToStore('authGuest', true);
  } catch (err) {
    // Set Aut State 0 - is logout state (Activation)
    dispatch(onAuthState(0));
    clearForLogout(dispatch);
  }
};

export const logout = () => async dispatch => {
  if (google?.accounts) google.accounts.id.disableAutoSelect();
  logEvent('logout');
  clearForLogout(dispatch);
};
