import { createReducer } from 'utils/reduxHelpers';
import { ON_LOGOUT } from 'app/Auth/actions/auth';
import { Api } from 'utils/connectors';
import { getArrayIds, getLang } from 'utils/appHelpers';
import { fillMoviesData } from 'app/Dash/actions/movie';
import { isMovieWatchFinished } from 'utils/movieHelper';
import { logEvent } from 'utils/analyticsHelper';

const ON_WATCH_LIST_GET = '@@home/ON_WATCH_LIST_GET';

export const onSetMyWatchList = payload => {
  return { type: ON_WATCH_LIST_GET, payload };
};

const myWatchListHandlers = {
  [ON_WATCH_LIST_GET]: (state, action) => {
    const result = { ...state, ...action.payload };
    return result;
  },
  [ON_LOGOUT]: () => {
    return [];
  },
};

export const getMyWatchList = isNext => async (dispatch, getState) => {
  try {
    const myWatchListStore = getState().myWatchList;
    const page = isNext ? myWatchListStore.number + 1 : 0;
    const params = {
      page,
      size: 20,
      locale: getLang(),
    };
    const { data } = await Api.get('/medias/seen', { params });
    dispatch(fillMoviesData([...data.content]));
    data.content = getArrayIds(data.content);
    if (isNext) data.content = [...myWatchListStore.content, ...data.content];
    dispatch(onSetMyWatchList(data));
  } catch (err) {
    throw err;
  }
};

export const onUpdateWatchingList = movie => async (dispatch, getState) => {
  const { myWatchList } = getState();
  if (!myWatchList) return;
  myWatchList.content = myWatchList.content || [];
  const isMovieInList = myWatchList.content.find(i => i.id === movie.id);
  const tempData = { ...myWatchList };
  if (!isMovieInList) {
    tempData.content.push({ id: movie.id });
  } else if (isMovieWatchFinished(movie)) {
    tempData.content = tempData.content.filter(i => i.id !== movie.id);
  }
  logEvent('continueWatching', {
    action: !isMovieInList ? 'added' : 'deleted',
    title: movie.title,
    mediaID: movie.id,
  });
  dispatch(onSetMyWatchList(tempData));
};

const myWatchListInitialData = null;

export const myWatchListReducer = createReducer(myWatchListInitialData, myWatchListHandlers);
