import { ON_LOGOUT } from 'app/Auth/actions/auth';
import { createReducer } from 'utils/reduxHelpers';

const ON_GET_PLANS = '@@user/ON_GET_PLANS';

export const onSetPlans = payload => ({ type: ON_GET_PLANS, payload });

const plansHandlers = {
  [ON_GET_PLANS]: (state, action) => {
    return action.payload;
  },
  [ON_LOGOUT]: () => {
    return [];
  },
};

const plansInitialData = [];

export const plansReducer = createReducer(plansInitialData, plansHandlers);
